<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title">Registrations To Audience Ratio</h4>
        <div class="d-flex overall-count">
          <div class="mr-2">Overall Count</div>
          <switches
            v-model="overallCount"
            type-bold="true"
            color="success"
            class="m-0"
          ></switches>
        </div>
      </div>
      <VueApexCharts
        type="bar"
        height="280"
        dir="ltr"
        :options="chartOptions"
        :series="series"
        :key="key"
      ></VueApexCharts>
    </div>
  </div>
</template>
<style scoped>
.register-grid {
  display: grid;
}
.apex-charts text {
  fill: #000 !important;
}
</style>
<script>
import VueApexCharts from "vue-apexcharts";
import Switches from "vue-switches";
import filterMixins from "../../../mixins/filterData";
import _ from "lodash";

export default {
  props: ["filters", "countData", "totalCountData"],
  mixins: [filterMixins],
  components: {
    VueApexCharts,
    Switches,
  },
  data() {
    return {
      title: "",
      count: [],
      total_count: [],
      overallCount: false,
      series: [
        {
          name: "Visitors",
          data: [
            {
              x: "Pre Live Event",
              y: 0,
              goals: [
                {
                  name: "Registrations",
                  value: 0,
                  strokeWidth: 5,
                  strokeHeight: 16,
                  strokeColor: "#1cbb8c",
                },
              ],
            },
            {
              x: "During Live Event",
              y: 0,
              goals: [
                {
                  name: "Registrations",
                  value: 0,
                  strokeWidth: 5,
                  strokeHeight: 16,
                  strokeColor: "#1cbb8c",
                },
              ],
            },
            {
              x: "Post Live Event",
              y: 0,
              goals: [
                {
                  name: "Registrations",
                  value: 0,
                  strokeWidth: 5,
                  strokeHeight: 16,
                  strokeColor: "#1cbb8c",
                },
              ],
            },
          ],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        colors: ["#5664d2"],
        dataLabels: {
          formatter: function (val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals;

            if (goals && goals.length) {
              return `${val} / ${goals[0].value}`;
            }
            return val;
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ["Audience", "Registrations"],
          markers: {
            fillColors: ["#1cbb8c", "#5664d2"],
          },
        },
      },
      key: 0,
      loading: true,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
    overallCount() {
      this.loadData();
    },
    countData: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    async loadData() {
      try {
        this.count = _.cloneDeep(this.countData);
        this.total_count = _.cloneDeep(this.totalCountData);
        if (this.overallCount == true) {
          this.series[0].data[0].y =
            this.total_count.total_registrations_pre_event_visited;
          this.series[0].data[0].goals[0].value =
            this.total_count.total_registrations_pre_event;

          this.series[0].data[1].y =
            this.total_count.total_registrations_during_event_visited;
          this.series[0].data[1].goals[0].value =
            this.total_count.total_registrations_during_event;

          this.series[0].data[2].y =
            this.total_count.total_registrations_post_event_visited;
          this.series[0].data[2].goals[0].value =
            this.total_count.total_registrations_post_event;
          this.key += 1;
        } else {
          this.series[0].data[0].y =
            this.count.total_registrations_pre_event_visited;
          this.series[0].data[0].goals[0].value =
            this.count.total_registrations_pre_event;

          this.series[0].data[1].y =
            this.count.total_registrations_during_event_visited;
          this.series[0].data[1].goals[0].value =
            this.count.total_registrations_during_event;

          this.series[0].data[2].y =
            this.count.total_registrations_post_event_visited;
          this.series[0].data[2].goals[0].value =
            this.count.total_registrations_post_event;
          this.key += 1;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
