<template>
  <div class="card register-grid">
    <div class="card-body d-flex justify-content-between align-items-center">
      <div>
        <h4 class="card-title">Current Audience</h4>
        <h3 class="mb-0">{{ count.total_live_event_vistors }}</h3>
      </div>
      <template v-if="statsData[0].subvalue">
        <div class="">
          <div
            class="badge font-size-14"
            :class="
              statsData[0].subvalue > 0
                ? 'badge-soft-success'
                : 'badge-soft-danger'
            "
          >
            {{ statsData[0].subvalue }} %
          </div>
          <div class="text-muted">{{ statsData[0].subtext }}</div>
        </div>
      </template>
    </div>
    <div
      class="card-body d-flex justify-content-between align-items-center border-top"
    >
      <div>
        <h4 class="card-title">Total Audience</h4>
        <h3 class="mb-0">{{ total_count.total_live_event_vistors }}</h3>
      </div>
      <template v-if="statsData[1].subvalue">
        <div class="">
          <div
            class="badge font-size-14"
            :class="
              statsData[1].subvalue > 0
                ? 'badge-soft-success'
                : 'badge-soft-danger'
            "
          >
            {{ statsData[1].subvalue }} %
          </div>
          <div class="text-muted">{{ statsData[1].subtext }}</div>
        </div>
      </template>
    </div>
  </div>
</template>
<style scoped>
.register-grid {
  display: grid;
}
</style>
<script>
import filterMixins from "../../../mixins/filterData";
import _ from "lodash";

export default {
  props: ["filters", "countData", "totalCountData"],
  mixins: [filterMixins],
  data() {
    return {
      count: 0,
      total_count: 0,
      statsData: [
        {
          subvalue: 0,
          subtext: "of Registrations",
        },
        {
          subvalue: 0,
          subtext: "of Registrations",
        },
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
    countData: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    async loadData() {
      try {
        this.count = _.cloneDeep(this.countData);
        this.total_count = _.cloneDeep(this.totalCountData);
        this.statsData[0].subvalue =
          this.count.total_registrations !== 0
            ? (
                (this.count.total_live_event_vistors /
                  this.count.total_registrations) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[0].subvalue = !isNaN(this.statsData[0].subvalue)
          ? this.statsData[0].subvalue
          : "0";
        this.statsData[1].subvalue =
          this.total_count.total_registrations !== 0
            ? (
                (this.total_count.total_live_event_vistors /
                  this.total_count.total_registrations) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[1].subvalue = !isNaN(this.statsData[1].subvalue)
          ? this.statsData[1].subvalue
          : "0";
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
