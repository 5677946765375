import moment from "moment";
export default {
  methods: {
    getCleanFilteredData(filters) {
      let countries_selected = [];
      if (filters.countries) {
        countries_selected = this.filters.countries.map((country) => {
          return country.name;
        });
      }
      let zones_selected = [];
      if (filters.zones) {
        zones_selected = this.filters.zones.map((zone) => {
          return zone.name;
        });
      }
      let states_selected = [];
      if (filters.state) {
        states_selected = this.filters.state.map((state) => {
          return state.name;
        });
      }
      let cities_selected = [];
      if (filters.city) {
        cities_selected = this.filters.city.map((city) => {
          return city.name;
        });
      }
      let specialities_ids_selected = [];
      if (filters.speciality) {
        specialities_ids_selected = this.filters.speciality.map(
          (speciality) => {
            return speciality.id;
          }
        );
      }
      let regionalManagers_selected = [];
      if (filters.region_managers) {
        regionalManagers_selected = this.filters.region_managers.map(
          (region_managers) => {
            return region_managers.name;
          }
        );
      }
      let areaManagers_selected = [];
      if (filters.area_managers) {
        areaManagers_selected = this.filters.area_managers.map(
          (area_managers) => {
            return area_managers.name;
          }
        );
      }
      let territoryManagers_selected = [];
      if (filters.territory_managers) {
        territoryManagers_selected = this.filters.territory_managers.map(
          (territory_managers) => {
            return territory_managers.name;
          }
        );
      }
      let partners_selected = [];
      if (filters.partner_ids) {
        partners_selected = this.filters.partner_ids.map((partner_ids) => {
          return partner_ids.id;
        });
      }
      let partnerDivisions_selected = [];
      if (filters.partner_division_ids) {
        partnerDivisions_selected = this.filters.partner_division_ids.map(
          (partner_division_ids) => {
            return partner_division_ids.id;
          }
        );
      }
      let live_event_selected = [];
      if (filters.live_event_ids) {
        live_event_selected = this.filters.live_event_ids.map(
          (live_event_ids) => {
            return live_event_ids.id;
          }
        );
      }
      let video_ids = [];
      if (filters.videos) {
        video_ids = this.filters.videos.map((video) => {
          return video.id;
        });
      }
      let question_type = [];
      if (filters.caseType) {
        question_type = this.filters.caseType.map((caseType) => {
          return caseType.name;
        });
      }
      let case_ids = [];
      if (filters.cases) {
        case_ids = this.filters.cases.map((cases) => {
          return cases.id;
        });
      }
      let telecallers_selected = [];
      if (filters.telecaller_ids) {
        telecallers_selected = this.filters.telecaller_ids.map(t => t.id);
      }

      let searchfilters = {};
      searchfilters.video_ids = video_ids;
      searchfilters.question_type = question_type;
      searchfilters.case_ids = case_ids;
      searchfilters.countries = countries_selected;
      searchfilters.zones = zones_selected;
      searchfilters.states = states_selected;
      searchfilters.cities = cities_selected;
      searchfilters.speciality_ids = specialities_ids_selected;
      searchfilters.telecaller_ids = telecallers_selected;
      searchfilters.member_types = this.filters.userType;
      if(this.doctorName != ''){
        searchfilters.doctor_name = this.doctorName;
      }
      if(this.mobileNumber != ''){
        searchfilters.mobile_number = this.mobileNumber;
      }
      if(filters.search != ''){
        searchfilters.search = this.filters.search;
      }
      searchfilters.region_managers = regionalManagers_selected;
      searchfilters.area_managers = areaManagers_selected;
      searchfilters.territory_managers = territoryManagers_selected;
      searchfilters.partner_ids = partners_selected;
      searchfilters.partner_division_ids = partnerDivisions_selected;
      searchfilters.live_event_ids = live_event_selected;
      let from_date =
        this.filters.daterange && this.filters.daterange.length > 0
          ? this.getFormatedDate(this.filters.daterange[0])
          : null;
      if (from_date) {
        searchfilters.from_date = from_date;
      }
      let to_date =
        this.filters.daterange &&
        this.filters.daterange.length > 0 &&
        this.filters.daterange[1]
          ? this.getFormatedDate(this.filters.daterange[1])
          : null;
      if (to_date) {
        searchfilters.to_date = to_date;
      }
      console.log(searchfilters);
      return searchfilters;
    },
    getFormatedDate(date) {
      try {
        if (date != null) {
          return moment(date).format("yyyy-MM-DD");
        }
        return null;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
  },
};
